<template>
  <div>
    <div v-if="!isLoading">
      <div class="uk-flex uk-flex-between uk-text-large">
        <div></div>
        <div class="uk-text-right">
          <div>
            Código de compra:
            <span class="uk-text-bold" test="redeem-code-display">{{
              redeem.code
            }}</span>
          </div>
          <div class="uk-text-default">
            {{ formatDatetime(redeem.created_at) }}
          </div>
        </div>
      </div>
      <div class="w-full mt-6">
        <div
          v-for="(shippingLabel, index) in redeem.shipping_labels"
          :key="index"
          class="border border-gray-300 rounded-lg mb-8 shadow-md justify-center"
        >
          <div
            class="flex items-center justify-center bg-gray-900 text-white py-4"
          >
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-16 h-16"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
                />
              </svg>
            </div>
            <div class="ml-4">
              <h2 class="text-white text-md m-0">
                {{ shippingLabel.address.first_name }}
                {{ shippingLabel.address.last_name }}
              </h2>
              <div class="">
                {{ shippingLabel.address.address }}, Zona
                {{ shippingLabel.address.zone }}
              </div>
              <div class="">
                {{ shippingLabel.address.city.name }},
                {{ shippingLabel.address.city.state.name }},
                {{ shippingLabel.address.city.state.country.spanish }}
              </div>
            </div>
          </div>

          <div class="flex items-center mt-4 p-4">
            <div v-if="shippingLabel.tracking_number">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-16 h-16 footer-link"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M8.25 18.75a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 0 1-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 0 0-3.213-9.193 2.056 2.056 0 0 0-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 0 0-10.026 0 1.106 1.106 0 0 0-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12"
                />
              </svg>
            </div>
            <div v-else>
              <div class="spinner"></div>
            </div>
            <div class="ml-6">
              <div class="font-bold">
                Número de rastreo:

                <span v-if="shippingLabel.tracking_number">
                  {{ shippingLabel.tracking_number }}
                </span>
                <span
                  v-else
                  class="bg-gray-900 text-sm rounded-full text-white px-4 py-2"
                >
                  {{
                    shippingLabelStatus(
                      shippingLabel.last_shipping_label_checkpoint
                        ? shippingLabel.last_shipping_label_checkpoint.status
                        : "REQUESTED"
                    )
                  }}
                </span>
                <spanm v-if="!shippingLabel.tracking_number" class="ml-4">
                  Te notificaremos cuando tu pedido esté listo, puedes seguir
                  navegando en la tienda.
                </spanm>
              </div>
              <div>
                <strong>Proveedor:</strong>
                Forza
              </div>
            </div>
          </div>
          <div
            v-for="(redeemItem, indexy) in redeem.redeem_items.filter(
              (redeemItem) =>
                redeemItem.shipping_labels[0].id === shippingLabel.id
            )"
            :key="'shipping-label-redeem-item-' + redeemItem.id"
            class="flex items-center p-4 border-t border-gray-300"
          >
            <div class="font-bold">{{ indexy + 1 }}</div>
            <div class="flex items-center w-1/2 px-2 ml-4">
              <div>
                <img
                  width="100px"
                  v-if="redeemItem.media.length"
                  :src="redeemItem.media[0].url"
                  alt=""
                />
              </div>
              <div class="ml-4">
                <h3>
                  {{
                    redeemItem.storefront_redeemable.redeemable_type ===
                    "App\\Models\\Product"
                      ? redeemItem.storefront_redeemable.display_name
                      : redeemItem.storefront_redeemable.redeemable.product
                          .storefront_redeemables[0].display_name +
                        " - " +
                        redeemItem.storefront_redeemable.redeemable.value
                  }}
                </h3>
              </div>
            </div>

            <div class="flex w-1/2 justify-around">
              <div class="text-center">
                <strong>Puntos:</strong> <br />
                {{ formatThousands(redeemItem.point_price) }}

                {{ pointsName }}
              </div>
              <div class="text-center ml-6">
                <strong>Cantidad:</strong>
                <br />
                {{ redeemItem.quantity }}
              </div>
              <div class="text-center ml-6">
                <strong>Total</strong>
                <br />
                {{
                  formatThousands(redeemItem.quantity * redeemItem.point_price)
                }}

                {{ pointsName }}
              </div>
            </div>
          </div>

          <div class="flex bg-gray-200 p-4 justify-center">
            <div>
              <div>
                <strong>Subtotal:</strong>
                <span class="ml-2">
                  {{
                    formatThousands(
                      redeem.redeem_items
                        .filter((redeemItem) => {
                          return (
                            redeemItem.shipping_labels[0].id ===
                            shippingLabel.id
                          );
                        })
                        .map((redeemItem) => {
                          return redeemItem.quantity * redeemItem.point_price;
                        })
                        .reduce((accum, current) => accum + current, 0)
                    )
                  }}
                  {{ pointsName }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div
          class="flex mt-6 bg-gray-100 p-4 items-center justify-center font-bold"
        >
          <div>Total de la compra</div>

          <div>
            <img v-if="pointsIcon" :src="pointsIcon" alt="" width="50px" />
          </div>

          <div>
            {{
              formatThousands(
                redeem.redeem_items
                  .filter(function (redeemItem) {
                    return !redeemItem.canceled;
                  })
                  .reduce(
                    (accum, redeemItem) =>
                      accum + redeemItem.point_price * redeemItem.quantity,
                    0
                  )
              )
            }}
            {{ pointsName }}
          </div>
        </div>
      </div>
    </div>
    <div v-else class="uk-text-center">
      <span uk-spinner></span>
    </div>
  </div>
</template>

<style>
.spinner {
  border: 5px solid #f3f3f3; /* Color de fondo */
  border-top: 5px solid #3498db; /* Color del spinner */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.redeemItemIsCanceled {
  text-decoration: line-through;
  color: coral;
}
</style>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ShowRedeem",

  data() {
    return {
      isLoading: true,
      redeem: null,
    };
  },

  computed: {
    ...mapGetters(["pointsName", "pointsIcon"]),
  },

  mounted() {
    this.getQuoteInfo();
  },

  methods: {
    getQuoteInfo() {
      this.isLoading = true;
      this.axios
        .get("/quotes/past-redeem/" + this.$route.params.id)
        .then(({ data: { redeem } }) => {
          this.redeem = redeem;

          this.redeem.redeem_items = this.redeem.redeem_items.map((item) => {
            item.media = item.storefront_redeemable.media;

            if (
              item.storefront_redeemable.redeemable_type ===
              "App\\Models\\ProductVariation"
            ) {
              item.media = [
                ...item.media,
                ...item.storefront_redeemable.redeemable.product
                  .storefront_redeemables[
                  item.storefront_redeemable.redeemable.product
                    .storefront_redeemables.length - 1
                ].media,
              ];
            }
            return item;
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
